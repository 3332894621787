import React, { Suspense } from 'react';
import Routes from '../RenderRoutes/RenderRoutes';

const Body: React.FC = () => {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <Routes></Routes>
            </Suspense>
        </>
    );
}

export default Body;